import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App" style={{backgroundColor: "#2e3440", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
      <h1 style={{color: "#eceff4", fontSize: "4rem", fontFamily: "ML"}}>Hello, Friend.</h1>
    </div>
  );
}

export default App;
